body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.h-120 {
  height: 20rem !important;
}

.h7 {
  font-size: 1rem !important;
}

body, html, #root {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

.hamburger {
  position: fixed;
  background-color: transparent;
  left: 0;
  top: 0;
  height: 30px;
  width: 30px;
  padding: 20px 20px;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transition: -webkit-transform 0.25s cubic-bezier(0.05, 1.04, 0.72, 0.98);
  transition: transform 0.25s cubic-bezier(0.05, 1.04, 0.72, 0.98);
  z-index: 1002;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.hamburger.is-active {
  background-color: none;
}

._layer {
  background: #333333;
  margin-bottom: 4px;
  border-radius: 2px;
  width: 28px;
  height: 4px;
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transition: all 0.25s cubic-bezier(0.05, 1.04, 0.72, 0.98);
  transition: all 0.25s cubic-bezier(0.05, 1.04, 0.72, 0.98);
}

.hamburger:hover .-top {
  -webkit-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  transform: translateY(-100%);
}

.hamburger:hover .-bottom {
  -webkit-transform: translateY(100%);
  -ms-transform: translateY(100%);
  transform: translateY(100%);
}

.hamburger.is-active .-top {
  -webkit-transform: translateY(200%) rotate(45deg) !important;
  -ms-transform: translateY(200%) rotate(45deg) !important;
  transform: translateY(200%) rotate(45deg) !important;
}

.hamburger.is-active .-mid {
  opacity: 0;
}

.hamburger.is-active .-bottom {
  -webkit-transform: translateY(-200%) rotate(135deg) !important;
  -ms-transform: translateY(-200%) rotate(135deg) !important;
  transform: translateY(-200%) rotate(135deg) !important;
}

.menuppal.is_active {
  transform: translate3d(0px, 0px, 0px);
}

.card {
  width: 50%;
}

@media (max-width: 720px) {
  .card {
    width: 100%;
  }
}

.menuppal {
  background-color: rgba(255, 255, 255, 0.95);
  bottom: 0;
  height: 100%;
  left: 0;
  overflow-y: scroll;
  position: fixed;
  top: 0;
  transform: translate3d(0px, -100%, 0px);
  transition: transform 0.35s cubic-bezier(0.05, 1.04, 0.72, 0.98) 0s;
  width: 100%;
  z-index: 1001;
}

.menuppal ul {
  margin: 0;
  padding: 0;
}

.menuppal ul li {
  list-style: none;
  text-align: center;
  font-family: Verdadna, Arial, Helvetica;
  /*color: $nav-color-text;*/
  font-size: 1.5rem;
  line-height: 3em;
  height: 3em;
  color: #369;
  text-transform: none;
  font-weight: bold;
}

.menuppal ul li a {
  text-decoration: none;
  color: #369;
}

.menuppal ul li a:hover {
  text-decoration: none;
  color: #333;
}

.logoSearch {
  border-radius: 2px !important;
  padding-bottom: .5em !important;
}

.sc-ifAKCX {
  background-color: #252728 !important;
  opacity: 1 !important;
  -webkit-transition: ease-in-out .5s;
  transition: ease-in-out 1s;
}

.romper {
  min-height: 200px;
}

.sc-ifAKCX:hover {
  opacity: .9 !important;
}

.inferior {
  position: relative;
  bottom: 0px;
}

.bg-warning-pastel {
  background-color: #ffff40 !important;
  background-repeat: repeat-x !important;
  background-repeat: repeat-y !important;
  background-position: top;
  background-position-y: top;
  width: 100% !important;
}

.h-110 {
  height: 10em !important;
}

.shadow, .shadowCard {
  -webkit-box-shadow: 10px 9px 5px -1px rgba(69, 67, 69, 1) !important;
  -moz-box-shadow: 10px 9px 5px -1px rgba(69, 67, 69, 1) !important;
  box-shadow: 10px 9px 5px -1px rgba(69, 67, 69, 1) !important;
}

.sc-bwzfXH, .gwZiig {
  overflow: hidden;
}

.font-size {
  font-size: 13px !important;
}

.hover-instagran:hover {
  color: white;
  background-color: rgb(248, 8, 88);
}

.hover-facebook:hover {
  background-color: rgb(227, 231, 233);
}

.hover-twetter:hover {
  background-color: rgb(227, 231, 233);
}

.hover-pintarest:hover {
  color: white;
  background-color: rgb(194, 18, 53);
}

.img-contenedor img {
  -webkit-transition: all .9s ease;
  /* Safari y Chrome */
  -moz-transition: all .9s ease;
  /* Firefox */
  -o-transition: all .9s ease;
  /* IE 9 */
  -ms-transition: all .9s ease;
  /* Opera */
  width: 100%;
}

.img-contenedor:hover img {
  -webkit-transform: scale(1.25);
  -moz-transform: scale(1.25);
  -ms-transform: scale(1.25);
  -o-transform: scale(1.25);
  transform: scale(1.25);
}

.shadow {
  -webkit-transition: all .9s ease !important;
  /* Safari y Chrome */
  -moz-transition: all .9s ease !important;
  /* Firefox */
  -o-transition: all .9s ease !important;
  /* IE 9 */
  -ms-transition: all .9s ease !important;
  /* Opera */
}

.shadow:hover {
  -webkit-transform: scale(1.10) !important;
  -moz-transform: scale(1.10) !important;
  -ms-transform: scale(1.10) !important;
  -o-transform: scale(1.10) !important;
  transform: scale(1.10) !important;
}

.w-20 {
  width: 20% !important;
}

.w-5 {
  width: 10% !important;
}

.logo-bank {
  width: 10% !important;
  height: 3em !important;
}

.img-total {
  width: 75% !important;
}

.list-none {
  list-style: none;
  text-decoration: none !important;
}

.bottom {
  top: 15%;
}

.hover-whatsapp:hover {
  background-color: white !important;
}

.decoration:hover {
  text-decoration: underline !important;
  cursor: pointer;
}

.btn-large {
  width: 50% !important;
}

.closes {
  width: 5% !important;
  position: relative !important;
  float: right !important;
}

.strike {
  z-index: 1 !important;
}

.size-imagen {
  width: 423px !important;
  height: 440px !important;
}

.modal-ext {
  width: 90% !important;
}

@media (max-width: 360px) {
  .context-hide {
    display: none !important;
  }
}

@media (min-width: 361px) {
  .buttonHide {
    display: none;
  }
  .meddiambutton {
    display: none !important;
    width: 100% !important;
  }
}

@media (max-width: 479px) {
  .hide-mode-pay {
    display: none;
  }
}

@media (max-width: 600px) {
  .size-imagen {
    height: 240px !important;
  }
  .responsive-design-btn {
    margin-top: .8em !important;
    margin-bottom: 0px !important;
  }
  .hamburger {
    position: absolute !important;
    left: auto !important;
    right: 2em !important;
    top: 1.3em !important
  }
  .whatsapp-responsive {
    position: fixed !important;
    background-color: #dcf8c6 !important;
    z-index: 2;
    right: 1.5em !important;
    bottom: 3em;
  }
  .whatsapp-responsive>span>svg {
    font-size: 1.5em !important;
  }
  .btn-car {
    margin-top: -.5em !important;
  }
  .btn-car>span>span>svg {
    width: 17% !important;
  }
  .facet_navbar, .hide {
    display: none !important;
  }
  .show-iten {
    display: block !important;
  }
  .icon-left {
    position: relative !important;
    width: 20% !important;
    margin-left: -1em;
  }
  .closes {
    position: absolute !important;
    top: 0px !important;
  }
  .btn-tab {
    width: 50% !important;
  }
  .modal-ext {
    width: 100% !important;
  }
  .btn-large, .img-total {
    width: 100% !important;
  }
  .fix {
    position: relative;
    margin-left: -4em;
    width: 70% !important;
    float: right;
  }
  .search {
    width: 10%;
    position: relative !important;
    float: right !important;
  }
  .hamburger {
    margin-top: -1em;
    position: relative;
    float: right;
    display: block !important;
  }
}

@media (min-width: 601px) {
  .hamburger, .show-iten {
    display: none !important;
  }
}

.btn-auth {
  position: absolute !important;
  justify-content: flex-end !important;
  top: 0px !important;
  right: 0px !important;
}

.box-shadow {
  -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.42) !important;
  -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.42) !important;
  box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.42) !important;
}

.gradiente {
  background: -moz-linear-gradient(left, rgba(241, 231, 103, 1) 0%, rgba(254, 182, 69, 1) 100%);
  background: -webkit-gradient(left top, right top, color-stop(0%, rgba(241, 231, 103, 1)), color-stop(100%, rgba(254, 182, 69, 1)));
  background: -webkit-linear-gradient(left, rgba(241, 231, 103, 1) 0%, rgba(254, 182, 69, 1) 100%);
}